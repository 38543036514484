<template>
  <div style="background-color: black;width: 100%;height: 100%;">
    <vue-particles
      style="height: 100%;position: fixed"
      color="#8ACAFF"
      :particle-opacity="0.9"
      :particles-number="80"
      shape-type="circle"
      :particle-size="4"
      lines-color="#8ACAFF"
      :lines-width="2"
      :line-linked="true"
      :line-opacity="0.1"
      :lines-distance="150"
      :move-speed="8"
      :hover-effect="true"
      hover-mode="grab"
      :click-effect="true"
      click-mode="push"
    />
    <div class="center-box">
      <img src="../../image/loading.gif" alt="" class="gif" />
      <div class="demo demo1">当前页面无权限或登录已失效</div>
      <el-button
        type="primary"
        size="small"
        v-waves
        @click="goNext"
        class="goNext"
        >返回登录页</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goNext() {
      // this.$router.go(-1);
      this.$router.push({name:'Login'})

    },
  },
};
</script>
<style lang="less" scoped>
.center-box {
  // border: 10px solid red;
  width: 60%;
  height: 60%;
  max-height: 550px;
  max-width: 1000px;
  min-width: 750px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  animation-name: move;
  animation-duration: 3s;
  .gif {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
  .goNext {
    margin: 0 auto;
    display: block;
  }
  /* 文字效果 */
  .demo {
    letter-spacing: 2px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 50px;
    font: bold 55px/100% "微软雅黑", "Lucida Grande", "Lucida Sans", Helvetica,
      Arial, Sans;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }
  .demo1 {
    color: #fff;
    text-shadow: 1px 1px rgba(197, 223, 248, 0.8),
      2px 2px rgba(197, 223, 248, 0.8), 3px 3px rgba(197, 223, 248, 0.8),
      4px 4px rgba(197, 223, 248, 0.8), 5px 5px rgba(197, 223, 248, 0.8),
      6px 6px rgba(197, 223, 248, 0.8);
  }
  .demo2 {
    color: #fff;
    text-shadow: -1px -1px rgba(197, 223, 248, 0.8),
      -2px -2px rgba(197, 223, 248, 0.8), -3px -3px rgba(197, 223, 248, 0.8),
      -4px -4px rgba(197, 223, 248, 0.8), -5px -5px rgba(197, 223, 248, 0.8),
      -6px -6px rgba(197, 223, 248, 0.8);
  }
}
@keyframes move {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
